import {
    ICount,
} from 'backend2/interfaces/common';
import {
    IFullHistory,
} from 'backend2/interfaces/history';
import {
    IFullOrder,
    IOrderWhy,
    IOrdersInsuranceGroupWhy,
} from 'backend2/interfaces/orders';
import {
    OrderType,
    OrderStatus,
    StatusesTransitionsConfig,
} from 'backend2/types/orders';
import {
    UserType,
} from 'backend2/types/users';
import uniq from 'lodash-es/uniq';

import UsersStore from 'src/stores/Users';
import {
    runInAction,
    makeAutoObservable,
} from 'mobx';

import {
    createOrders,
    preCreateOrders,

    getOrders,
    getOrdersHistory,

    updateOrderCashAndAnnouncedValue,
    updateOrderManualControl,
    updateOrderLocation,
    updateOrderWeight,
    updateOrderStatus,
    updateOrderSimple,
    updateOrderCustomer,
    updateOrderExecutor,
    updateOrderKind,
    updateOrderClose,
    updateOrderTiming,

    removeOrder,
    restoreOrder,
    closeOrder,
    openOrder,
    recalculateOrders,
    acceptOrder,

    getOrderStatusesConfig,

    getOrderFastTravelStatuses,
    getOrderTransitionStatuses,
    getCanIUpdate,

    getOrderWhy,
    getOrdersInsuranceGroupsWhy,
} from 'src/dataProviders/orders';


class ObservableOrdersStore {
    orders: Record<string, IFullOrder> = {};
    hotOrderIds: string[] = [];
    ordersHistory: Record<string, IFullHistory[]> = {};
    _statusesConfig: {
        statusesTransitionsConfig: StatusesTransitionsConfig;
        permissibleUpdateOrderStatusByUserType: Record<UserType, OrderStatus[]>;
    } = {
        statusesTransitionsConfig: {} as any,
        permissibleUpdateOrderStatusByUserType: {} as any,
    };
    ordersTransitionStatuses: Record<string, OrderStatus[]> = {};
    ordersFastTravelStatuses: Record<string, OrderStatus[]> = {};
    isOrdersUpdatable: Record<string, boolean> = {};
    ordersWhy: Record<string, IOrderWhy[]> = {};
    ordersInsuranceGroupWhy: Record<string, IOrdersInsuranceGroupWhy[]> = {};

    public DELIVERY_PROCESSING_ORDER_STATUSES = [
        OrderStatus.CommonProcessingAccepted,
        OrderStatus.DeliveryTakingOnTheWay,
        OrderStatus.DeliveryTakingDone,
        OrderStatus.DeliveryDeliveryOnTheWay,
    ];

    constructor() {
        makeAutoObservable(this, null, {autoBind: true});
    }

    get allOrders() {
        return Object.values(this.orders);
    }

    get hotOrders() {
        return this.getOrders(this.hotOrderIds);
    }

    getOrders(skdOrderIds: string[]) {
        return skdOrderIds.map(id => this.getOrder(id)).filter(Boolean);
    }

    getOrder(skdOrderId: string) {
        return this.orders[skdOrderId];
    }

    getCourierOrders(courierId: string, settlementDate: string) {
        return this.allOrders
            .filter(fullOrder => fullOrder.order.typeEnum === OrderType.Delivery)
            .filter(fullOrder => fullOrder.delivery.executorUserId === Number(courierId))
            .filter(fullOrder => fullOrder.order.settlementDate === settlementDate);
    }

    getOrdersByExternalId(externalId: string) {
        return this.allOrders
            .filter(fullOrder => fullOrder.order.externalId === externalId);
    }

    getOrderHistory(skdOrderId: string) {
        return this.ordersHistory[skdOrderId] || [];
    }

    get statusesConfig() {
        return this._statusesConfig;
    }

    getOrderTransitionStatuses(skdOrderId: string | bigint) {
        return this.ordersTransitionStatuses[String(skdOrderId)] || [];
    }

    getOrderFastTravelStatuses(skdOrderId: string | bigint) {
        return this.ordersFastTravelStatuses[String(skdOrderId)] || [];
    }

    getIsOrderUpdatable(skdOrderId: string | bigint) {
        return this.isOrdersUpdatable[String(skdOrderId)] || true;
    }

    getOrderWhyList(skdOrderId: string | bigint) {
        return this.ordersWhy[String(skdOrderId)] || [];
    }

    getOrdersInsuranceGroupWhyList(settlementDate: string, customerUserId: number) {
        const key = constructOrdersInsuranceGroupsWhyKey(settlementDate, customerUserId);
        return this.ordersInsuranceGroupWhy[key] || [];
    }

    async createOrders(...params: Parameters<typeof createOrders>) {
        const orders = await createOrders(...params);
        for (const order of orders) {
            this.orders[order.order.id] = order;
        }
    }

    async preCreateOrders(...params: Parameters<typeof preCreateOrders>) {
        return preCreateOrders(...params);
    }

    async fetchOrders(params: Parameters<typeof getOrders>[0], isHot = false) {
        if (params.ids) {
            params.ids = uniq(params.ids);
            if (!params.ids) {
                return [];
            }
        }
        const orders = await getOrders(params);
        runInAction(() => {
            const patch: typeof this.orders = {};
            for (const fullOrder of orders) {
                patch[fullOrder.order.id] = fullOrder;
            }
            this.orders = {...this.orders, ...patch};
            if (isHot) {
                this.hotOrderIds = orders.map(order => String(order.order.id));
            }
        });
        return orders;
    }

    async fetchOrder(orderId: string | bigint) {
        const orders = this.fetchOrders({
            ids: [orderId],
        });
        return orders[0];
    }

    async fetchOrdersCount(params: Parameters<typeof getOrders>[0]) {
        const ordersCount = await getOrders({
            ...params,
            isCountMode: true,
        });
        // @ts-ignore
        ordersCount.count = BigInt(ordersCount.count);
        return ordersCount as unknown as ICount;
    }

    async fetchOrdersHistory(params: Parameters<typeof getOrdersHistory>[0]) {
        const histories = await getOrdersHistory(params);
        runInAction(() => {
            const patch: typeof this.ordersHistory = {};
            for (const history of histories) {
                const entityId = String(history.fieldEntityId);
                if (!patch[entityId]) {
                    patch[entityId] = [];
                }
                patch[entityId].push(history);
            }
            this.ordersHistory = {...this.ordersHistory, ...patch};
        });
        return histories;
    }

    async fetchOrdersHistoryCount(params: Parameters<typeof getOrdersHistory>[0]) {
        const historiesCount = await getOrdersHistory({
            ...params,
            isCountMode: true,
        });
        // @ts-ignore
        historiesCount.count = BigInt(historiesCount.count);
        return historiesCount as unknown as ICount;
    }

    updateDecorator<T>(func: Function) {
        return async (orderId: string | bigint, body: T) => {
            const prevFullOrder = this.orders[String(orderId)];
            const isPrevOrderClosed = prevFullOrder.order.isClose;
            if (isPrevOrderClosed) {
                await this.openOrder(orderId);
            }

            let isNextOrderClosed = false;
            try {
                const nextFullOrder = await func.call(this, orderId, body);
                runInAction(() => {
                    this.orders[nextFullOrder.order.id] = nextFullOrder;
                });
                isNextOrderClosed = nextFullOrder.order.isClose;
            } finally {
                if (isPrevOrderClosed && !isNextOrderClosed) {
                    await this.closeOrder(orderId);
                }
            }
        };
    }
    updateOrderCashAndAnnouncedValue = this.updateDecorator<Parameters<typeof updateOrderCashAndAnnouncedValue>[1]>((...params:  Parameters<typeof updateOrderCashAndAnnouncedValue>) => {
        return updateOrderCashAndAnnouncedValue(...params);
    });
    updateOrderLocation = this.updateDecorator<Parameters<typeof updateOrderLocation>[1]>((...params: Parameters<typeof updateOrderLocation>) => {
        return updateOrderLocation(...params);
    });
    updateOrderManualControl = this.updateDecorator<Parameters<typeof updateOrderManualControl>[1]>((...params: Parameters<typeof updateOrderManualControl>) => {
        return updateOrderManualControl(...params);
    });
    updateOrderWeight = this.updateDecorator<Parameters<typeof updateOrderWeight>[1]>((...params: Parameters<typeof updateOrderWeight>) => {
        return updateOrderWeight(...params);
    });
    updateOrderStatus = this.updateDecorator<Parameters<typeof updateOrderStatus>[1]>((...params: Parameters<typeof updateOrderStatus>) => {
        return updateOrderStatus(...params);
    });
    updateOrderSimple = this.updateDecorator<Parameters<typeof updateOrderSimple>[1]>((...params:  Parameters<typeof updateOrderSimple>) => {
        return updateOrderSimple(...params);
    });
    updateOrderCustomer = this.updateDecorator<Parameters<typeof updateOrderCustomer>[1]>((...params: Parameters<typeof updateOrderCustomer>) => {
        return updateOrderCustomer(...params);
    });
    updateOrderExecutor = this.updateDecorator<Parameters<typeof updateOrderExecutor>[1]>((...params: Parameters<typeof updateOrderExecutor>) => {
        return updateOrderExecutor(...params);
    });
    updateOrderKind = this.updateDecorator<Parameters<typeof updateOrderKind>[1]>((...params: Parameters<typeof updateOrderKind>) => {
        return updateOrderKind(...params);
    });
    updateOrderClose = this.updateDecorator<Parameters<typeof updateOrderClose>[1]>((...params: Parameters<typeof updateOrderClose>) => {
        return updateOrderClose(...params);
    });
    updateOrderTiming = this.updateDecorator<Parameters<typeof updateOrderTiming>[1]>((...params: Parameters<typeof updateOrderTiming>) => {
        return updateOrderTiming(...params);
    });

    actionDecorator<T>(func: Function) {
        return async (orderId: string | bigint, body?: T) => {
            const nextFullOrder = await func.call(this, orderId, body);
            this.orders[nextFullOrder.order.id] = nextFullOrder;
        };
    }
    acceptOrder = this.actionDecorator((...params: Parameters<typeof acceptOrder>) => {
        return acceptOrder(...params);
    });
    closeOrder = this.actionDecorator((...params: Parameters<typeof closeOrder>) => {
        return closeOrder(...params);
    });
    openOrder = this.actionDecorator((...params: Parameters<typeof openOrder>) => {
        return openOrder(...params);
    });

    async recalculateOrders(body: Parameters<typeof recalculateOrders>[0]) {
        const nextFullOrders = await recalculateOrders(body);
        for (const nextFullOrder of nextFullOrders) {
            this.orders[nextFullOrder.order.id] = nextFullOrder;
        }
    }

    async removeOrder(orderId: string | bigint) {
        const prevFullOrder = this.orders[String(orderId)];
        const isPrevOrderClosed = prevFullOrder.order.isClose;
        if (isPrevOrderClosed) {
            await openOrder(orderId);
        }
        const nextFullOrder = await removeOrder(orderId);
        this.orders[nextFullOrder.order.id] = nextFullOrder;
    }

    async restoreOrder(body: Parameters<typeof restoreOrder>[0]) {
        const nextFullOrder = await restoreOrder(body);
        this.orders[nextFullOrder.order.id] = nextFullOrder;
    }

    // config
    async getOrderStatusesConfig() {
        this._statusesConfig = await getOrderStatusesConfig();
    }

    async fetchOrderTransitionStatuses(orderId: string | bigint) {
        // чтобы между изменением статуса и считываем актуальных переходов, не показывать старые переходы, дублируя текущий статус
        this.ordersFastTravelStatuses[String(orderId)] = [];
        this.ordersTransitionStatuses[String(orderId)] = [];

        const ordersFastTravelStatuses = await getOrderFastTravelStatuses(orderId);
        const ordersTransitionStatuses = await getOrderTransitionStatuses(orderId);

        runInAction(() => {
            this.ordersFastTravelStatuses[String(orderId)] = ordersFastTravelStatuses;
            this.ordersTransitionStatuses[String(orderId)] = ordersTransitionStatuses;
        });
    }

    async getCanIUpdate(orderId: string | bigint) {
        const isOrdersUpdatable = await getCanIUpdate(orderId);
        runInAction(() => {
            this.isOrdersUpdatable[String(orderId)] = isOrdersUpdatable;
        });
    }

    async getOrderWhy(...params: Parameters<typeof getOrderWhy>) {
        const orderWhyList = await getOrderWhy(...params);
        runInAction(() => {
            const patch: typeof this.ordersWhy = {};
            for (const orderWhy of orderWhyList) {
                const {
                    orderId,
                } = orderWhy;
                if (!patch[orderId]) {
                    patch[orderId] = [];
                }
                patch[orderId].push(orderWhy);
            }
            this.ordersWhy = {...this.ordersWhy, ...patch};
        });
    }

    async getOrdersInsuranceGroupsWhy(...params: Parameters<typeof getOrdersInsuranceGroupsWhy>) {
        const ordersInsuranceGroupsWhyList = await getOrdersInsuranceGroupsWhy(...params);
        runInAction(() => {
            // todo: если значений будет больше 1000 то перезапишется второй limit на первый
            const patch: typeof this.ordersInsuranceGroupWhy = {};
            for (const ordersInsuranceGroupsWhy of ordersInsuranceGroupsWhyList) {
                const {
                    settlementDate,
                    customerUserId,
                } = ordersInsuranceGroupsWhy;

                const key = constructOrdersInsuranceGroupsWhyKey(settlementDate, customerUserId);
                if (!patch[key]) {
                    patch[key] = [];
                }
                patch[key].push(ordersInsuranceGroupsWhy);
            }
            this.ordersInsuranceGroupWhy = {...this.ordersInsuranceGroupWhy, ...patch};
        });
    }
}

function constructOrdersInsuranceGroupsWhyKey(settlementDate: string, customerUserId: number) {
    return `${settlementDate}__${customerUserId}`;
}

const OrdersStore = new ObservableOrdersStore();
export default OrdersStore;
